var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("合包申请")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "container info-card" },
          [
            _c(
              "el-form",
              {
                staticClass: "search-form",
                attrs: { inline: true },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return (() => {}).apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "合包订单号", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.search()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.searchForm.merge_package_num,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "merge_package_num", $$v)
                        },
                        expression: "searchForm.merge_package_num",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "用户编码", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.search()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.searchForm.user_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "user_code", $$v)
                        },
                        expression: "searchForm.user_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          placeholder: "合包状态",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.search()
                          },
                        },
                        model: {
                          value: _vm.searchForm.status,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "status",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.status",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "合包中", value: "3" },
                        }),
                        _c("el-option", {
                          attrs: { label: "待合包", value: "2" },
                        }),
                        _c("el-option", {
                          attrs: { label: "已完成", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "已取消", value: "0" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "z-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container info-card" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.list, stripe: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "package_num",
                    label: "合并订单单号",
                    width: "200",
                    fixed: "left",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                underline: false,
                                target: "_blank",
                                href: `/dashboard?logistics_code=${scope.row.package_num}`,
                              },
                            },
                            [_vm._v(_vm._s(scope.row.package_num))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "user.code", label: "用户编码", width: "100" },
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      prop: "package_num",
                      label: "包裹单号",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "点击查看更多",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPackageAttrList(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.package_attrs[0]
                                            .package_attr_num
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "span",
                        [
                          _vm._v(" 被合并包裹信息 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "点击包裹单号显示详情",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-info" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "package_total",
                    label: "被合并包裹数量",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "express_title", label: "下单(合包)线路" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "created", label: "申请时间", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("formatDate")(scope.row.created)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "状态", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == 2
                            ? _c("el-tag", { attrs: { size: "small" } }, [
                                _vm._v(
                                  " " + _vm._s(scope.row.status_label) + " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 1
                            ? _c(
                                "el-tag",
                                { attrs: { size: "small", type: "success" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.status_label) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          scope.row.status == 0
                            ? _c(
                                "el-tag",
                                { attrs: { size: "small", type: "info" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.status_label) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          scope.row.status == 3
                            ? _c(
                                "el-tag",
                                { attrs: { size: "small", type: "warning" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.status_label) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "status",
                    label: "操作",
                    width: "200",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startPacking(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 开始合包 ")]
                              )
                            : _vm._e(),
                          scope.row.status == 3
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "success",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.mergeConfirm(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 完成合包 ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("z-pagination", {
              attrs: {
                total: _vm.pageConfig.total,
                "page-size": _vm.pageConfig.size,
                "current-page": _vm.pageConfig.current_page,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.pageConfig, "current_page", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.pageConfig, "current_page", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.packageAttrDialog.visible,
            "close-on-click-modal": false,
            center: "",
            title: "包裹列表",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.packageAttrDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "package-attr-dialog" },
            [
              _c(
                "div",
                {
                  staticClass: "package-info",
                  staticStyle: { display: "flex" },
                },
                [
                  _c("p", [
                    _vm._v(
                      "订单号：" + _vm._s(_vm.packageAttrDialog.package_num)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "总重量(kg)：" +
                        _vm._s(_vm.packageAttrDialog.package_total_weight)
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "总数量：" + _vm._s(_vm.packageAttrDialog.package_total)
                    ),
                  ]),
                ]
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.packageAttrDialog.list,
                    stripe: "",
                    "max-height": "600px",
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_attr_num",
                      label: "包裹单号",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  target: "_blank",
                                  href: `/dashboard?logistics_code=${scope.row.package_attr_num}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.package_attr_num))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shelf_code", label: "货架编号" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "weight", label: "重量(kg)" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "express_title", label: "线路" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "package_note", label: "包裹备注" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { formatDate } from '@/utils/date'
import { Loading } from 'element-ui'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private isLoading = false
	private searchForm: any = {
		user_code: '',
		merge_package_num: '',
		status: ''
	}
	private list = []

	private validateForm = {
		waybill: [{ required: true, message: '请扫描包裹号', trigger: 'blur' }],
		shelf_code: [{ required: true, message: '请扫描货架', trigger: 'blur' }]
	}

	private packageAttrDialog = {
		visible: false,
		package_num: '',
		package_total: 0,
		package_total_weight: 0,
		list: []
	}

	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}

	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}

	async created() {
		this.search()
	}
	async openPackageAttrList(row: any) {
		this.packageAttrDialog.package_num = row.package_num
		this.packageAttrDialog.list = row.package_attrs
		this.packageAttrDialog.package_total = row.package_total
		this.packageAttrDialog.package_total_weight = row.package_total_weight
		this.packageAttrDialog.visible = true
	}
	async search() {
		const { size, current_page } = this.pageConfig
		this.isLoading = true

		const queryStatus = this.searchForm.status.toString()
		const params = {
			page_size: size,
			page_index: current_page,
			...this.searchForm,
			status: queryStatus
		}
		this.isLoading = true
		const { data } = await this.$axios
			.get('/v1/jobline/packages/merge-list', {
				params: params
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (!data) {
			return
		}
		this.list = data.list
		this.pageConfig.total = data.page.total
	}
	/**
	 * 开始合包
	 */
	async startPacking(row: any) {
		const isConfirm = await this.$confirm('是否确认？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return
		const loadingInstance = Loading.service({
			fullscreen: true
		})
		const { data } = await this.$axios
			.put(`/v1/jobline/packages/merge-start/${row.id}`)
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => loadingInstance.close())
		if (!data) {
			return
		}
		this.$message.success('操作成功!')
		this.search()
	}
	/**
	 * 完成合包
	 */
	async mergeConfirm(row: any) {
		console.log(row)
		const isConfirm = await this.$confirm('是否确认已完成合包？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return
		const { data } = await this.$axios
			.put(`/v1/jobline/packages/merge-confirm/${row.id}`)
			.catch(() => {
				return {
					data: null
				}
			})
		if (!data) {
			return
		}
		this.$message.success('操作成功!')
		this.search()
	}
}
